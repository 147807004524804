import { z } from 'zod'

const apiBaseURL = import.meta.env.RENDERER_VITE_BACKEND_URL

export const stringDefaultNullable = z
  .string()
  .optional()
  .nullable()
  .transform((val) => val ?? '')

export const priceSchema = z.object({
  amount: z.number(),
  formatted: z.string()
})

export const priceTotals = z.object({
  total: z.number(),
  subtotal: z.number(),
  discount: z.number(),
  tax: z.number(),
  points: z.number()
})

export type PriceTotals = z.infer<typeof priceTotals>

/* SaleType */
export const SaleTypeEnum = {
  EATIN: 'EATIN',
  TAKEOUT: 'TAKEOUT'
} as const

export const saleTypeUnion = z.union([
  z.literal(SaleTypeEnum.EATIN),
  z.literal(SaleTypeEnum.TAKEOUT)
])

export type SaleType = z.infer<typeof saleTypeUnion>

/* OptionGroups Type: Type and Schema */
export const OptionsGroupsType = {
  CHOICE: 'CHOICE',
  COMMENT: 'COMMENT',
  CONDIMENT: 'CONDIMENT',
  CANADD: 'CANADD',
  COMPONENT: 'COMPONENT',
  SUGGESTIVE: 'SUGGESTIVE'
} as const

export const optionsGroupsTypeSchema = z.union([
  z.literal(OptionsGroupsType.CHOICE),
  z.literal(OptionsGroupsType.COMMENT),
  z.literal(OptionsGroupsType.CONDIMENT),
  z.literal(OptionsGroupsType.CANADD),
  z.literal(OptionsGroupsType.COMPONENT),
  z.literal(OptionsGroupsType.SUGGESTIVE)
])

export const localImageSchema = z
  .string()
  .optional()
  .transform((str?: string) => {
    if (!str) return ''
    const { host, protocol } = new URL(apiBaseURL)
    if (str.includes(host)) return str
    const url = new URL(str)
    url.host = host
    url.protocol = protocol
    return url.toString()
  })

export const zAny = z.any()

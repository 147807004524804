import {
  CUSTOM_IM_ANALYTICS_ERRORS,
  GTMEcommerceItem,
  GTMEvent,
  GtmEventName,
  GTMEventType,
  GTMViewContentGroup,
  LoginMethod,
  QrTypes
} from '@lib/models/gtm'
import { Order } from '@lib/models/order'
import { ProductLiteCategorized, Product, ProductSize } from '@lib/models/product'
import { CartProduct } from '@lib/models/purchase'
import {
  getItemListNameTypeFromProducts,
  gtmEcommerceItemFromCartProduct,
  gtmEcommerceItemFromLiteCategorized,
  gtmEcommerceItemFromProduct,
  gtmEcommerceItemFromSize,
  generatePageTitle,
  formatAmountGTM
} from '@lib/utils/gtmUtils'
import { logDebug, logInfo, scopedLogger } from '@lib/utils/logUtils'
import { useAppStore } from '@stores/app.store'
import { useGtmStore } from '@stores/gtm.store'
import { computed } from 'vue'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any
  }
}
const log = scopedLogger('@composables/useGtm.ts')

export const useGtm = () => {
  const appStore = useAppStore()
  const gtmStore = useGtmStore()

  const restaurantCode = computed(() => appStore.config?.code ?? '')
  const currency = computed(() => appStore.config?.currencyAcronym ?? '')
  const cartTotals = computed(() => appStore.cartTotals)
  const cartProducts = computed(() => appStore.cart?.productsTotals || [])
  const hasBill = computed(() => Boolean(appStore.fiscalFields))

  const viewContent = computed(() => {
    return gtmStore.viewGTMContent
  })

  /* Create events general info */
  const eventGeneralInfo = (evType: GTMEventType): GTMEvent => {
    const event = new GTMEvent(evType)
    event.country = appStore.config?.country
    event.kiosk_number = appStore.config?.posNumber
    // view
    const content = viewContent.value
    if (content) {
      event.content_group = content.cg
      event.content_group2 = content.cg2
      event.content_group3 = content.cg3
      event.page_title = generatePageTitle(content) || undefined
      event.link_url = content.linkUrl
    }
    // user
    if (appStore.user) {
      event.is_loyalty = appStore.user.loyalty
      event.user_id = appStore.user.mcId
    }
    // ecommerce
    event.ecommerce.restaurant_code = restaurantCode.value
    return event
  }

  /* Events */
  const trackGoEcommerce = () => {
    const go_ecommerce = eventGeneralInfo(GtmEventName.GO_ECOMMERCE)
    sendEvent(go_ecommerce)
  }

  const trackGoLogin = () => {
    const go_login = eventGeneralInfo(GtmEventName.GO_LOGIN)
    sendEvent(go_login)
  }

  const trackLogin = (method: string) => {
    const login = eventGeneralInfo(GtmEventName.LOGIN)
    login.method = method
    sendEvent(login)
  }

  const trackLoginError = ({ errorType, method }: { errorType: string; method: string }) => {
    const login_error = eventGeneralInfo(GtmEventName.LOGIN_ERROR)
    login_error.error_code = `${CUSTOM_IM_ANALYTICS_ERRORS.LOGIN_ERROR}`
    login_error.error_type = errorType
    login_error.method = method
    sendEvent(login_error)
  }

  const trackLogout = () => {
    const logout = eventGeneralInfo(GtmEventName.LOGOUT)
    sendEvent(logout)
  }

  const trackMenuButton = (button: string) => {
    const menu_button = eventGeneralInfo(GtmEventName.MENU(button))
    sendEvent(menu_button)
  }

  const trackGetBill = () => {
    const get_bill = eventGeneralInfo(GtmEventName.GET_BILL)
    sendEvent(get_bill)
  }

  const trackGoMym = () => {
    const go_mym = eventGeneralInfo(GtmEventName.GO_MYM)
    sendEvent(go_mym)
  }

  const trackMymHome = () => {
    const mym_home = eventGeneralInfo(GtmEventName.MYM_HOME)
    sendEvent(mym_home)
  }

  const trackViewItemList = ({
    items,
    itemListName
  }: {
    items: GTMEcommerceItem[]
    itemListName?: string
  }) => {
    const view_item_list = eventGeneralInfo(GtmEventName.VIEW_ITEM_LIST)
    // ecommerce
    view_item_list.ecommerce.currency = currency.value
    const sortedItems = items.toSorted((a, b) => {
      if (a.index && b.index) return a.index - b.index
      return 0
    })
    view_item_list.ecommerce.items = sortedItems
    view_item_list.ecommerce.item_list_name =
      itemListName ?? getItemListNameTypeFromProducts(sortedItems)
    sendEvent(view_item_list)
  }

  const trackSelectItemCategorized = (item: ProductLiteCategorized) => {
    const select_item = eventGeneralInfo(GtmEventName.SELECT_ITEM)
    // ecommerce
    const gtmEcommerceItem = gtmEcommerceItemFromLiteCategorized({
      product: item,
      currency: currency.value
    })
    select_item.ecommerce.items = [gtmEcommerceItem]
    select_item.ecommerce.currency = currency.value
    sendEvent(select_item)
  }

  const trackSelectItemSize = (item: ProductSize) => {
    const select_item = eventGeneralInfo(GtmEventName.SELECT_ITEM)
    // ecommerce
    const gtmEcommerceItem = gtmEcommerceItemFromSize({
      product: item,
      currency: currency.value
    })
    select_item.ecommerce.items = [gtmEcommerceItem]
    select_item.ecommerce.currency = currency.value
    sendEvent(select_item)
  }

  const trackViewItem = (item: Product) => {
    const view_item = eventGeneralInfo(GtmEventName.VIEW_ITEM)
    // ecommerce
    const gtmEcommerceItem = gtmEcommerceItemFromProduct({
      product: item,
      currency: currency.value
    })
    view_item.ecommerce.items = [gtmEcommerceItem]
    view_item.ecommerce.currency = currency.value
    sendEvent(view_item)
  }

  const trackCustomizeItem = (product: Product) => {
    const customize_item = eventGeneralInfo(GtmEventName.CUSTOMIZE_ITEM)

    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })

    customize_item.ecommerce.items = [item]
    sendEvent(customize_item)
  }

  const trackSelectComponent = (product: Product, componentInfo: { id: string; name: string }) => {
    const select_component = eventGeneralInfo(GtmEventName.SELECT_COMPONENT)

    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })
    item.component_id = componentInfo.id
    item.component_name = componentInfo.name

    select_component.ecommerce.items = [item]
    sendEvent(select_component)
  }

  const trackAdditionalComponent = ({ itemId, itemName }: { itemId: string; itemName: string }) => {
    const additional_component = eventGeneralInfo(GtmEventName.ADDITIONAL_COMPONENT)

    const item = new GTMEcommerceItem()
    item.item_id = itemId
    item.item_name = itemName

    additional_component.ecommerce.items = [item]
    sendEvent(additional_component)
  }

  const trackCustomizeIngredients = (
    product: Product,
    componentInfo?: { id: string; name: string }
  ) => {
    const customize_ingredients = eventGeneralInfo(GtmEventName.CUSTOMIZE_INGREDIENTS)

    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })
    if (componentInfo) {
      item.component_id = componentInfo.id
      item.component_name = componentInfo.name
    }
    customize_ingredients.ecommerce.items = [item]
    sendEvent(customize_ingredients)
  }

  const trackSaveIngredients = (
    product: Product,
    ingredientsEdited: string,
    componentInfo?: { id: string; name: string }
  ) => {
    const save_ingredients = eventGeneralInfo(GtmEventName.SAVE_INGREDIENTS)
    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })
    if (componentInfo) {
      item.component_id = componentInfo.id
      item.component_name = componentInfo.name
    }
    item.ingredients_edited = ingredientsEdited
    save_ingredients.ecommerce.items = [item]
    sendEvent(save_ingredients)
  }

  const trackAddToCart = ({
    item,
    units,
    computedUnitPrice
  }: {
    item: Product
    units: number
    computedUnitPrice: number
  }) => {
    const add_to_cart = eventGeneralInfo(GtmEventName.ADD_TO_CART)
    const gtmEcommerceItem = gtmEcommerceItemFromLiteCategorized({
      product: item,
      currency: currency.value,
      computedUnitPrice
    })
    gtmEcommerceItem.quantity = units
    add_to_cart.ecommerce.items = [gtmEcommerceItem]
    add_to_cart.ecommerce.currency = currency.value
    add_to_cart.ecommerce.item_list_name = getItemListNameTypeFromProducts([gtmEcommerceItem])
    sendEvent(add_to_cart)
  }

  const trackGoCart = () => {
    const go_cart = eventGeneralInfo(GtmEventName.GO_CART)
    sendEvent(go_cart)
  }

  const trackViewCart = (cartProducts: CartProduct[]) => {
    const view_cart = eventGeneralInfo(GtmEventName.VIEW_CART)

    const items = cartProducts.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    view_cart.ecommerce.items = items
    view_cart.ecommerce.item_list_name = getItemListNameTypeFromProducts(items)
    sendEvent(view_cart)
  }

  const trackRemoveFromCart = (cartProducts: CartProduct[]) => {
    const remove_from_cart = eventGeneralInfo(GtmEventName.REMOVE_FROM_CART)

    const items = cartProducts.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    remove_from_cart.ecommerce.items = items
    remove_from_cart.ecommerce.item_list_name = getItemListNameTypeFromProducts(items)

    sendEvent(remove_from_cart)
  }

  const trackApplyCoupon = () => {
    const apply_coupon = eventGeneralInfo(GtmEventName.APPLY_COUPON)
    sendEvent(apply_coupon)
  }

  const trackContinueOrdering = () => {
    const continue_ordering = eventGeneralInfo(GtmEventName.CONTINUE_ORDERING)
    sendEvent(continue_ordering)
  }

  const trackBeginCheckout = (cartProducts: CartProduct[]) => {
    const begin_checkout = eventGeneralInfo(GtmEventName.BEGIN_CHECKOUT)
    const items = cartProducts.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    begin_checkout.ecommerce.items = items
    begin_checkout.ecommerce.currency = currency.value
    begin_checkout.ecommerce.item_list_name = getItemListNameTypeFromProducts(items)
    sendEvent(begin_checkout)
  }

  const trackAddShippingInfo = (cartProducts: CartProduct[]) => {
    const add_shipping_info = eventGeneralInfo(GtmEventName.ADD_SHIPPING_INFO)
    const items = cartProducts.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    add_shipping_info.ecommerce.items = items
    add_shipping_info.ecommerce.currency = currency.value
    add_shipping_info.ecommerce.item_list_name = getItemListNameTypeFromProducts(items)
    // loyalty && points_spent
    sendEvent(add_shipping_info)
  }

  const trackAddPaymentInfo = ({ paymentType }: { paymentType: string }) => {
    const add_payment_info = eventGeneralInfo(GtmEventName.ADD_PAYMENT_INFO)
    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    add_payment_info.ecommerce.items = items
    add_payment_info.ecommerce.currency = currency.value
    add_payment_info.ecommerce.payment_type = paymentType.toLowerCase()
    add_payment_info.ecommerce.discount = formatAmountGTM(cartTotals.value.discount)
    add_payment_info.ecommerce.tax = formatAmountGTM(cartTotals.value.tax)
    add_payment_info.ecommerce.bill = hasBill.value
    // coupons && loyalty && points_spent
    sendEvent(add_payment_info)
  }

  const trackOrderFailed = () => {
    const order_failed = eventGeneralInfo(GtmEventName.ORDER_FAILED)
    sendEvent(order_failed)
  }

  const trackPurchase = ({
    order,
    eventOrigin = 'adk'
  }: {
    order: Order
    eventOrigin?: string
  }) => {
    const purchase = eventGeneralInfo(GtmEventName.PURCHASE)
    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    purchase.ecommerce.items = items
    purchase.ecommerce.currency = currency.value
    purchase.ecommerce.payment_type = order.paymentMethod.toLowerCase()
    purchase.ecommerce.transaction_id = order.orderId
    purchase.ecommerce.discount = formatAmountGTM(cartTotals.value.discount)
    purchase.ecommerce.tax = formatAmountGTM(cartTotals.value.tax)
    purchase.ecommerce.value = formatAmountGTM(cartTotals.value.total)
    purchase.ecommerce.bill = hasBill.value
    purchase.ecommerce.item_list_name = getItemListNameTypeFromProducts(items)
    purchase.event_origin = eventOrigin
    sendEvent(purchase)
  }

  const trackSelectPickUpSelector = (selector: string) => {
    const select_pickup_selector = eventGeneralInfo(GtmEventName.SELECT_PICKUP_SELECTOR(selector))
    sendEvent(select_pickup_selector)
  }

  const trackSelectPickUp = (pickupType: string) => {
    const select_pickup = eventGeneralInfo(GtmEventName.SELECT_PICKUP)
    select_pickup.ecommerce.pickup_type = pickupType
    sendEvent(select_pickup)
  }

  const trackOrderCancelledBefore = () => {
    const order_cancelled_before = eventGeneralInfo(GtmEventName.ORDER_CANCELLED_BEFORE)
    sendEvent(order_cancelled_before)
  }

  const trackRedeem = ({ coupon, method }: { coupon: string; method: string }) => {
    const redeem = eventGeneralInfo(GtmEventName.REDEEM)
    //ecommerce
    redeem.method = method
    if (method === LoginMethod.QR_IDENTIFICATION) redeem.qr_type = QrTypes.COUPON
    redeem.ecommerce.currency = currency.value
    redeem.ecommerce.coupon = coupon
    sendEvent(redeem)
  }

  const trackCustomError = ({ code, type }: { code: string; type: string }) => {
    const custom_error = eventGeneralInfo(GtmEventName.CUSTOM_ERROR)
    custom_error.error_code = code
    custom_error.error_type = type
    sendEvent(custom_error)
  }

  const trackInactivityPopup = () => {
    const inactivity_popup = eventGeneralInfo(GtmEventName.INACTIVITY_POPUP)
    sendEvent(inactivity_popup)
  }

  const trackInactivityExit = () => {
    const inactivity_exit = eventGeneralInfo(GtmEventName.INACTIVITY_EXIT)
    sendEvent(inactivity_exit)
  }

  /* PageView */
  const trackPageView = (content: GTMViewContentGroup, modal = false) => {
    gtmStore.setViewGTMContent(content, modal)
    const page_view = eventGeneralInfo(GtmEventName.PAGE_VIEW)

    sendEvent(page_view)
  }

  const trackOnCloseModal = () => {
    const previousPageViewContent = gtmStore.pageViewGTMContent
    if (previousPageViewContent) {
      trackPageView(previousPageViewContent)
    }
  }

  /* Send events method */
  const sendEvent = (ev: GTMEvent) => {
    if (!window) logInfo(log, `Event on server, will not be sended [${ev.event}]`)
    else {
      if (!window.dataLayer) window.dataLayer = []
      window.dataLayer.push(ev)
      logInfo(log, `New event pushed to datalayer [${ev.event}]`)
      logDebug(log, ev as unknown as Record<string, unknown>)
    }
  }

  return {
    // pageView
    trackPageView,
    trackOnCloseModal,
    // events
    trackGoEcommerce,
    trackGoLogin,
    trackLogin,
    trackLoginError,
    trackLogout,
    trackMenuButton,
    trackGetBill,
    trackGoMym,
    trackMymHome,
    trackViewItemList,
    trackSelectItemCategorized,
    trackSelectItemSize,
    trackViewItem,
    trackCustomizeItem,
    trackSelectComponent,
    trackAdditionalComponent,
    trackCustomizeIngredients,
    trackSaveIngredients,
    trackAddToCart,
    trackGoCart,
    trackViewCart,
    trackRemoveFromCart,
    trackApplyCoupon,
    trackContinueOrdering,
    trackBeginCheckout,
    trackAddShippingInfo,
    trackAddPaymentInfo,
    trackOrderFailed,
    trackPurchase,
    trackSelectPickUpSelector,
    trackSelectPickUp,
    trackOrderCancelledBefore,
    trackCustomError,
    trackRedeem,
    trackInactivityPopup,
    trackInactivityExit
  }
}

import { enable, disable } from './cookies'
;(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).ElectronCookies = {
    enable,
    disable
  }
})()

export { enable, disable }

<script setup lang="ts">
import { computed, markRaw, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SlotDialog from './ui/modal/SlotDialog.vue'
import InactivitySlot from './ui/modal/inactivity/InactivitySlot.vue'
import { useAppStore } from '@stores/app.store'
import { isPlaywright } from '@lib/utils/testUtils'
import { scopedLogger, logInfo } from '@lib/utils/logUtils'
import { useGtm } from '@composables/useGtm'

const log = scopedLogger('@component/InteractionKeepAlive')

const STANDARD_INACTIVITY_SECONDS = 120
const STANDARD_INACTIVITY_ALERT_SECONDS = 30

const store = useAppStore()
const router = useRouter()
const route = useRoute()
const { trackInactivityPopup, trackInactivityExit } = useGtm()

const dialog = ref(false)
const interval = ref()
const timeout = ref()

const inactivitySeconds = computed(() => {
  if (isPlaywright()) return 5
  return store.config?.inactivitySeconds ?? STANDARD_INACTIVITY_SECONDS
})

const alertSeconds = computed(() => {
  if (isPlaywright()) return 5
  return store.config?.inactivityAlertSeconds ?? STANDARD_INACTIVITY_ALERT_SECONDS
})

const standardIntervalMillis = computed(() => {
  return inactivitySeconds.value * 1000
})

router.afterEach(() => {
  onUserInteraction()
})

const canCreateInterval = () => {
  if (['splash', 'attract'].includes(route.name as string)) return false
  if (route.name === 'checkout') {
    const step = route.query.step?.toString()
    if (step && ['payment-method', 'withdrawal-method', 'table-service-number'].includes(step))
      return true
    else return false
  }
  return true
}

const reset = async () => {
  onUserInteraction()
  // will reset ADK ...
  store.resetADK = true
  await router.push({ name: 'splash' })
}

const closeInterval = () => {
  clearInterval(interval.value)
  interval.value = undefined
}

const closeTimeout = () => {
  clearTimeout(timeout.value)
  timeout.value = undefined
  dialog.value = false
}

const createInterval = () => {
  if (canCreateInterval()) {
    interval.value = setInterval(function () {
      onFinishedInterval()
    }, standardIntervalMillis.value)
  }
}

function onFinishedInterval() {
  logInfo(log, 'INACTIVE: Inactivity interval has ended, showing alert for closing session')
  closeInterval()
  dialog.value = true
  trackInactivityPopup()

  timeout.value = setTimeout(function () {
    logInfo(log, 'INACTIVE: Timeout ended, user is inactive, session will be closed')
    trackInactivityExit()
    reset()
  }, alertSeconds.value * 1000)
}

function onUserInteraction() {
  closeTimeout()
  closeInterval()
  if (!interval.value) {
    createInterval()
  }
}

onBeforeMount(() => {
  window.addEventListener('click', onUserInteraction)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', onUserInteraction)
})
</script>

<template>
  <div data-test="interaction-keep-alive" class="absolute top-0 left-auto">
    <SlotDialog
      v-if="dialog"
      :data="{ countdown: alertSeconds }"
      :slot-component="markRaw(InactivitySlot)"
      class="!z-[11]"
    />
  </div>
</template>

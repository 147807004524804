import { z } from 'zod'

export const customerDto = z.object({
  mcId: z.string(),
  name: z.string(),
  loyalty: z.boolean(),
  points: z.number()
})

export type User = z.infer<typeof customerDto>

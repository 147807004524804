<script setup lang="ts">
import { onMounted, ref } from 'vue'
import AdkButton from '@components/ui/AdkButton.vue'
import { useLocale } from '@composables/useLocale'

defineEmits(['action'])
const props = defineProps<{ data: { countdown: number } }>()

const { t } = useLocale()

const secondsLeft = ref(props.data.countdown)

onMounted(async () => {
  for (let i = secondsLeft.value - 1; i >= 0; i--) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    secondsLeft.value = i
  }
})
</script>

<template>
  <section data-test="slot-inactivity" class="flex flex-col items-center gap-[4.58vh] pb-32">
    <div class="flex flex-col w-[78.98vw] gap-[2.92vh] items-center">
      <img
        class="h-[7.50vh] w-[13.33vw]"
        src="/images/navigation/warning.svg"
        :alt="t('modals.inactivity.title')"
      />
      <div class="flex flex-col gap-[1.25vh] text-center">
        <h1 data-test="slot-dialog_title" class="text-h1 font-bold">
          {{ t('modals.inactivity.title') }}
        </h1>
        <p data-test="slot-dialog_description" class="text-h3">
          {{ t('modals.inactivity.description') }}
        </p>
        <span data-test="slot-dialog_countdown" class="block text-h2 font-bold">
          {{ secondsLeft }}
        </span>
        <p data-test="slot-dialog_postdescription" class="text-h3">
          {{ t('modals.inactivity.postDescription') }}
        </p>
      </div>
    </div>
    <div class="flex gap-[2.22vw]">
      <AdkButton class="w-[34.81vw]" size="large" is-primary @press="$emit('action', 'confirm')">{{
        t('actions.accept')
      }}</AdkButton>
    </div>
  </section>
</template>

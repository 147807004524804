import { Cookie, CookieJar } from 'tough-cookie'
import WebStorageCookieStore from './web-storage.js'

interface IOptions {
  origin?: string
}

const globalExists = typeof global != 'undefined'

class WindowMirror {
  static get document(): Document {
    return globalExists ? global.document : document
  }

  static get location(): Location {
    return globalExists ? global.location : location
  }

  static get localStorage(): Storage {
    return globalExists ? global.localStorage : localStorage
  }
}

const DEFAULT_APP_URI = 'https://mcd-adk.com'

export function enable(options?: IOptions) {
  const origin = options?.origin ?? DEFAULT_APP_URI
  const cookieStore = new WebStorageCookieStore(window.localStorage)
  const cookieJar = new CookieJar(cookieStore)

  Object.defineProperty(WindowMirror.document, 'cookie', {
    enumerable: true,
    configurable: true,
    get() {
      return cookieJar.getCookieStringSync(origin)
    },
    set(cookie) {
      cookieJar.setCookieSync(Cookie.parse(cookie, { loose: true }) ?? '', origin)
    }
  })
}

export function disable(): void {
  // delete WindowMirror.document.cookie
  WindowMirror.document.cookie = ''
}

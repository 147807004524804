import { z } from 'zod'
import { AxiosInstance, AxiosRequestConfig, isAxiosError } from 'axios'

import { isZodError } from '../../types/typesUtils'
import { LogFunctions } from 'electron-log'
import { useGtm } from '@composables/useGtm'
import { extractCustomError } from '@lib/utils/gtmUtils'
import { logErrorDebug } from '@lib/utils/logUtils'
import { LogBody, LogKind, LogOwnLevel } from '@lib/models/internal/log'

export abstract class BaseService {
  abstract readonly client: AxiosInstance

  protected async request<T extends z.ZodType>(
    config: AxiosRequestConfig,
    schema: T,
    logger: LogFunctions
  ): Promise<z.infer<T>> {
    try {
      const res = await this.client(config)
      return await schema.parseAsync(res.data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const { trackCustomError } = useGtm()
      const requestUrl = `${this.client.defaults.baseURL}${config.url}`

      if (isAxiosError(error)) {
        const { response } = error
        const errData = response?.data
        const errStatus = response?.status ?? 500
        const data: LogBody = {
          type: LogOwnLevel.ERROR,
          kind: LogKind.AXIOS_ERROR,
          data: {
            status: errStatus,
            requestUrl,
            response: errData || error.message
          }
        }
        logErrorDebug(logger, data)
      } else if (isZodError(error)) {
        const data: LogBody = {
          type: LogOwnLevel.ERROR,
          kind: LogKind.ZOD_ERROR,
          data: {
            requestUrl,
            errors: error.errors
          }
        }
        logErrorDebug(logger, data)
      } else {
        const data: LogBody = {
          type: LogOwnLevel.ERROR,
          kind: LogKind.ERROR,
          data: {
            requestUrl,
            message: error.message
          }
        }
        logErrorDebug(logger, data)
      }

      trackCustomError(
        extractCustomError({
          error
        })
      )
      throw error
    }
  }
}

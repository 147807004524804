import {
  GTMEcommerceItem,
  GtmProductType,
  GTMProductTypes,
  GTMViewContentGroup,
  ItemSize
} from '@lib/models/gtm'
import { ProductLiteCategorized, Product, ProductSize } from '@lib/models/product'
import { CartProduct } from '@lib/models/purchase'
import { useAppStore } from '@stores/app.store'
import get from 'lodash/get'
import { isPlaywright } from './testUtils'

export const createGTMScript = (containerId: string) => {
  // special behaviour for tests
  const gtmContainerID = isPlaywright() ? 'GTM-XXXYYYY' : containerId
  return `
  (function(w,d,s,l,i){
    w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmContainerID}');`
}

export const createGTMNoScriptSrc = (containerId: string) => {
  return `https://www.googletagmanager.com/ns.html?id=${containerId}`
}

export const generatePageTitle = (content: GTMViewContentGroup) => {
  if (content.pageTitle) return content.pageTitle
  const arr = [content.cg, content.cg2, content.cg3].filter((x) => x)
  return arr.join('/')
}

export const getItemListNameTypeFromProducts = (
  products: GTMEcommerceItem[]
): GtmProductType | undefined => {
  let crossSellingCMS = false
  let crossSellingNBO = false
  let category
  for (const product of products) {
    if (product.item_list_name === GTMProductTypes.CROSS_SELLING_CMS) crossSellingCMS = true
    if (product.item_list_name === GTMProductTypes.CROSS_SELLING_NBO) crossSellingNBO = true
    if (crossSellingCMS && crossSellingNBO) return GTMProductTypes.CROSS_SELLING_MIX
    else category = product.item_category
  }
  if (crossSellingCMS) return GTMProductTypes.CROSS_SELLING_CMS
  if (crossSellingNBO) return GTMProductTypes.CROSS_SELLING_NBO
  return category
}

export const formatAmountGTM = (amount: number) => (!amount ? 0 : amount / 100)

export const formatItemSize = (size: string | undefined) => {
  if (size === 'NONE' || !size) return ItemSize.UNIQUE
  return ItemSize[size]
}

const productPriceAmountGTM = (product: ProductLiteCategorized | Product) =>
  product.unifiedPrice?.amount
    ? formatAmountGTM(product.unifiedPrice.amount)
    : formatAmountGTM(product.price.amount)

export const gtmEcommerceItemFromLiteCategorized = ({
  product,
  itemListName,
  currency,
  computedUnitPrice
}: {
  product: ProductLiteCategorized
  itemListName?: string
  currency: string
  computedUnitPrice?: number
}): GTMEcommerceItem => {
  const price = computedUnitPrice
    ? formatAmountGTM(computedUnitPrice)
    : productPriceAmountGTM(product)

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.identifier,
    item_list_name: itemListName,
    size: formatItemSize(product.size),
    price,
    quantity: 1,
    loyalty: false,
    points: 0,
    item_category: gtmEcommerceItemGetCategory(product.idCategory)
  }
}

export const gtmEcommerceItemFromSize = ({
  product,
  itemListName,
  currency
}: {
  product: ProductSize
  itemListName?: string
  currency: string
}): GTMEcommerceItem => {
  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.code,
    item_list_name: itemListName,
    size: formatItemSize(product.id),
    price: formatAmountGTM(product.price.amount),
    quantity: 1,
    loyalty: false,
    points: 0,
    item_category: gtmEcommerceItemGetCategory(product.idCategory)
  }
}

export const gtmEcommerceItemFromProduct = ({
  product,
  itemListName,
  currency
}: {
  product: Product
  itemListName?: string
  currency: string
}): GTMEcommerceItem => {
  const price = productPriceAmountGTM(product)

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.identifier,
    item_list_name: itemListName,
    is_combo: product.combo,
    size: formatItemSize(product.size),
    price,
    quantity: 1,
    loyalty: false,
    points: 0,
    item_category: gtmEcommerceItemGetCategory(product.idCategory)
  }
}

export const gtmEcommerceItemGetCategory = (productIdCategory: string) => {
  const appStore = useAppStore()

  const productCategory = appStore.categories.find(
    (category) => category.idCategory === productIdCategory
  )

  return productCategory ? productCategory.title.toLowerCase() : ''
}

export const gtmEcommerceItemFromCartProduct = ({
  cartProduct,
  currency
}: {
  cartProduct: CartProduct
  currency: string
}): GTMEcommerceItem => {
  const price = formatAmountGTM(cartProduct.priceWithExtras)
  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: cartProduct.name,
    item_id: cartProduct.identifier,
    price,
    quantity: cartProduct.units,
    item_category: gtmEcommerceItemGetCategory(cartProduct.idCategory)
    // loyalty && points
  }
}

export const extractCustomError = ({
  error,
  customHttpStatus = undefined
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  customHttpStatus?: number
}) => {
  // defaults
  const defaultStatusCode = 500
  const defaultMessage = 'Internal Server Error'

  const errResponse = error?.response || undefined

  // status
  const statusCode = get(errResponse, 'status')

  if (!errResponse || !statusCode)
    return { code: `${defaultStatusCode}`, type: error?.name || defaultMessage }

  // messages
  const message = get(errResponse, 'data.message')
  // IM message
  const errors = get(errResponse, 'data.error', {})
  const IMErrorMessageKey = Object.keys(errors).length
    ? errors.message
    : get(errResponse, 'data.code', '')
  const stringifiedResponse = JSON.stringify(get(errResponse, 'data', defaultMessage))

  const code = customHttpStatus ?? statusCode ?? defaultStatusCode
  const type = IMErrorMessageKey || message || stringifiedResponse

  return {
    code: `${code}`,
    type
  }
}

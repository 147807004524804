import { SaleTypeEnum } from './common'

export const GtmEventName = {
  PAGE_VIEW: 'page_view',
  GO_ECOMMERCE: 'go_ecommerce',
  GO_LOGIN: 'go_login',
  LOGIN: 'login',
  LOGIN_ERROR: 'login_error',
  LOGOUT: 'logout',
  MENU_HOME: 'menu_home',
  MENU_OFFERS: 'menu_offers',
  MENU_LOYALTY: 'menu_loyalty',
  MENU_BILL: 'menu_bill',
  MENU: (buttonType: string) => {
    switch (buttonType) {
      case 'aop-coupon':
        return GtmEventName.MENU_OFFERS
      case 'loyalty':
        return GtmEventName.MENU_LOYALTY
      case 'bill':
        return GtmEventName.MENU_BILL
      default:
        return GtmEventName.MENU_HOME
    }
  },
  GET_BILL: 'get_bill',
  GO_MYM: 'go_mym',
  MYM_HOME: 'mym_home',
  VIEW_ITEM_LIST: 'view_item_list',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM: 'view_item',
  CUSTOMIZE_ITEM: 'customize_item',
  SELECT_COMPONENT: 'select_component',
  ADDITIONAL_COMPONENT: 'additional_component',
  CUSTOMIZE_INGREDIENTS: 'customize_ingredients',
  SAVE_INGREDIENTS: 'save_ingredients',
  ADD_TO_CART: 'add_to_cart',
  GO_CART: 'go_cart',
  VIEW_CART: 'view_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  APPLY_COUPON: 'apply_coupon',
  CONTINUE_ORDERING: 'continue_ordering',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_PAYMENT_INFO: 'add_payment_info',
  ORDER_FAILED: 'order_failed',
  PURCHASE: 'purchase',
  FRONT_PURCHASE: 'front_purchase',
  SELECT_PICKUP_RESTAURANT: 'select_pickup_restaurant',
  SELECT_PICKUP_TAKE_AWAY: 'select_pickup_take_away',
  SELECT_PICKUP: 'select_pickup',
  SELECT_PICKUP_SELECTOR: (type: string) =>
    type === SaleTypeEnum.EATIN
      ? GtmEventName.SELECT_PICKUP_RESTAURANT
      : GtmEventName.SELECT_PICKUP_TAKE_AWAY,
  ORDER_CANCELLED_BEFORE: 'order_cancelled_before',
  REDEEM: 'redeem',
  CUSTOM_ERROR: 'custom_error',
  INACTIVITY_POPUP: 'inactivity_popup',
  INACTIVITY_EXIT: 'inactivity_exit'
} as const

export type GTMEventType = (typeof GtmEventName)[keyof typeof GtmEventName]

export const CUSTOM_IM_ANALYTICS_ERRORS = {
  IM_API_ERROR: 3001,
  FORM_ERROR: 3002,
  LOGIN_ERROR: 3005
} as const

export const GTMContentGroup = {
  primary: {
    USER: 'user',
    HOME: 'home',
    ECOMMERCE: 'ecommerce',
    LOYALTY: 'loyalty', // TO DO
    COUPONS: 'coupons'
  },
  secondary: {
    LOGIN: 'login',
    SELECT_LANGUAGE: 'select_language',
    HOME: 'home',
    ITEM_LIST: 'item_list',
    ITEM_DETAIL: 'item_detail',
    CART: 'cart',
    CROSS_SELLING: 'cross_selling',
    PAYMENT: 'payment',
    ORDER_FAILED: 'order_failed',
    ORDER_CANCELLED: 'order_cancelled',
    CONFIRMATION: 'confirmation',
    ORDER: 'order',
    ERROR: 'error'
  },
  tertiary: {
    WELCOME: 'welcome',
    ERROR: 'error',
    ITEM_ID: (itemId: string) => `${itemId}`,
    ID_RFM: (idRfm: string) => `${idRfm}`,
    EMPTY: 'empty',
    SELECT_DELIVERY: 'select_delivery',
    PICKUP_TAKEAWAY: 'pickup_takeaway',
    PICKUP_TAKEAWAY_THANKYOU: 'pickup_takeaway/thankyou',
    PICKUP_RESTAURANT: 'pickup_restaurant',
    PICKUP_RESTAURANT_TABLE_NUMBER: 'pickup_restaurant/table_number',
    PICKUP_RESTAURANT_ORDER_NUMBER: 'pickup_restaurant/order_number',
    PICKUP_RESTAURANT_THANKYOU: 'pickup_restaurant/thankyou',
    BANNER: 'banner' // TO DO
  }
} as const

/**
 * Types
 *  */

/* PageView */

export type GTMViewContentGroup = {
  cg?: string
  cg2?: string
  cg3?: string
  pageTitle?: string
  linkUrl?: string
}

export const EventOrigin = {
  DATALAYER: 'dl',
  ADK: 'ADK'
} as const

export const EventQRType = {
  COUPON: 'coupon',
  USER_LOGIN: 'user_login'
} as const

export const EventPickupType = {
  TABLE: 'table',
  COUNTER: 'counter'
} as const

export const ItemSize = {
  SMALL: 'S',
  MEDIUM: 'M',
  LARGE: 'L',
  UNIQUE: 'unica'
} as const

export const LoginMethod = {
  IDENTIFICATION_NUMBER: 'identification_number',
  QR_IDENTIFICATION: 'QR_identification'
} as const

export const QrTypes = {
  COUPON: 'coupon',
  USER_LOGIN: 'user_login'
} as const

export type ItemSizeType = (typeof ItemSize)[keyof typeof ItemSize]

export const EventDeliveryType = 'MOP'

export class GTMEcommerceItem {
  index?: number = undefined
  currency?: string
  item_name?: string
  item_id?: string
  price?: number
  quantity?: number
  coupon?: string = undefined
  discount?: number = 0
  loyalty?: boolean = undefined
  points?: number = 0
  item_list_name?: string = undefined
  item_category?: string = undefined
  pre_order?: boolean = undefined
  is_combo?: boolean = undefined
  size?: ItemSizeType = undefined
  /* Customization */
  // Choice option inside CHOICE group
  component_name?: string = undefined
  component_id?: string = undefined
  // string of ingredients edited
  ingredients_edited?: string = undefined
}

export class GtmEcommerce {
  transaction_id?: string = undefined
  value?: number = undefined
  payment_type?: string = undefined
  payment_type2?: string = undefined
  tax?: number = undefined
  shipping?: number = undefined
  discount?: number = undefined
  tip?: number = undefined
  bill?: boolean = undefined
  currency?: string = undefined
  coupon?: string = undefined
  coupon2?: string = undefined
  coupon3?: string = undefined
  delivery_type?: string = EventDeliveryType
  restaurant_code?: string = undefined
  restaurant_type?: string = 'restaurant'
  points_spent?: number = undefined
  promotion_name?: string = undefined
  promotion_id?: string = undefined
  loyalty?: boolean = undefined
  item_name?: string = undefined
  items?: GTMEcommerceItem[] = undefined
  pickup_type?: string = undefined
  item_list_name?: string = undefined
  cancelation_type?: string = undefined
  cancelation_warning?: string = undefined
  pre_order?: boolean = undefined
}

export class GTMEvent {
  event: GTMEventType
  event_origin?: string = EventOrigin.DATALAYER
  // page view
  content_group?: string = undefined
  content_group2?: string = undefined
  content_group3?: string = undefined
  page_title?: string = undefined
  // user
  user_id?: string = undefined
  country?: string
  is_loyalty?: boolean = undefined
  loyalty_points?: number = undefined
  user_type?: string = undefined
  // other properties
  content_type?: string = undefined
  method?: string = undefined
  error_code?: string = undefined
  error_type?: string = undefined
  form_name?: string = undefined
  search_term?: string = undefined
  outbound?: boolean = undefined
  link_url?: string = undefined
  warning_type?: string = undefined
  offer_code?: string = undefined
  offer_id?: string = undefined
  offer_name?: string = undefined
  comment?: string = undefined
  wt_step?: string = undefined
  wt_type?: string = undefined
  category_name?: string = undefined
  category_index?: number = undefined
  qr_type?: string = undefined
  group_id?: string = undefined
  kiosk_number?: string = undefined
  // ecommerce
  ecommerce: GtmEcommerce = new GtmEcommerce()

  constructor(ev: GTMEventType) {
    this.event = ev
  }
}

export const GTMProductTypes = {
  CROSS_SELLING: 'cross_selling',
  CROSS_SELLING_NBO: 'cross_selling_nbo',
  CROSS_SELLING_CMS: 'cross_selling_cms',
  CROSS_SELLING_MIX: 'cross_selling_mix'
} as const

export type GtmProductType = (typeof GTMProductTypes)[keyof typeof GTMProductTypes]

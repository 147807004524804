<script setup lang="ts">
import { useLocale } from '@composables/useLocale'
import { User } from '@lib/models/identification'
import { formatNumeric } from '@lib/utils/formatUtils'

defineProps<{
  user?: User
  locale: string
  decimals: number
}>()

const { t } = useLocale()
</script>
<template>
  <div class="px-[1.48vw] flex flex-col gap-[0.63vh]">
    <div class="flex flex-row gap-[0.74vw]">
      <p class="text-h5">{{ t('loyaltyBlock.salute') }}</p>
      <p class="text-h5 font-bold">{{ user?.name }}!</p>
    </div>
    <div>
      <p class="text-small">{{ t('loyaltyBlock.availablePoints') }}</p>
      <p class="bg-clip-text text-transparent font-bold text-h4 bg-loyalty-gradient-ba">
        {{ formatNumeric(user?.points ?? 0, locale, decimals) }} pts
      </p>
    </div>
  </div>
</template>

import { GTMViewContentGroup } from '@lib/models/gtm'
import { defineStore } from 'pinia'

interface State {
  viewGTMContent?: GTMViewContentGroup
  pageViewGTMContent?: GTMViewContentGroup
}

export const useGtmStore = defineStore('gtm', {
  state: (): State => ({
    viewGTMContent: undefined,
    pageViewGTMContent: undefined
  }),
  actions: {
    setViewGTMContent(content: GTMViewContentGroup, modal = false) {
      this.viewGTMContent = content
      if (!modal) this.pageViewGTMContent = content
    }
  }
})

<script lang="ts" setup>
import FullScreenLoader from './ui/FullScreenLoader.vue'
import ConfirmDialog from './ui/modal/ConfirmDialog.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { useAppStore } from '@stores/app.store'
import { logInfo, scopedLogger } from '@lib/utils/logUtils'
import { ConfirmDialogProps } from '@lib/models/modals'
import { useLocale } from '@composables/useLocale'
import { GTMContentGroup, LoginMethod } from '@lib/models/gtm'
import { useGtm } from '@composables/useGtm'
import { aopService } from '@lib/services/backend/aop/aop.service'
import { useErrorApi } from '@composables/useErrorApi'
import { BackendCustomException } from '@lib/models/internal/errors'

const scannerActiveRouteNames = [
  'attract',
  'prehome',
  'identification',
  'home',
  'category',
  'cart',
  'aop-coupon'
]

const log = scopedLogger('@component/ScannerKeepAlive.vue')

const store = useAppStore()
const { t } = useLocale()
const route = useRoute()
const router = useRouter()
const { trackLogin, trackRedeem } = useGtm()
const { logErrorApi, getErrorKey } = useErrorApi()

const scanned = ref<string>()
const processing = ref<'identification' | 'coupon'>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { reveal, onConfirm, onCancel } = createConfirmDialog(ConfirmDialog as any)

const identificationErrorDialogProps: ConfirmDialogProps = {
  title: t('modals.identificationMcIdError.title'),
  description: t('modals.identificationMcIdError.description'),
  primaryButton: t('actions.tryAgain'),
  secondaryButton: t('actions.cancel'),
  imageUrl: 'images/icons/error.svg',
  contentGroup: {
    cg: GTMContentGroup.primary.USER,
    cg2: GTMContentGroup.secondary.LOGIN,
    cg3: GTMContentGroup.tertiary.ERROR
  }
}

const couponErrorDialogProps: ConfirmDialogProps = {
  title: t('modals.aopCouponError.title'),
  description: t('modals.aopCouponError.description'),
  primaryButton: t('actions.tryAgain'),
  secondaryButton: t('actions.cancel'),
  imageUrl: 'images/icons/error.svg'
}

const identificationSuccesDialogProps = (userName: string) => {
  return {
    title: t('modals.identificationSuccess.title', { user: userName }),
    description: t('modals.identificationSuccess.description'),
    primaryButton: t('actions.continue'),
    secondaryButton: undefined,
    imageUrl: 'images/mcd.png',
    contentGroup: {
      cg: GTMContentGroup.primary.USER,
      cg2: GTMContentGroup.secondary.LOGIN,
      cg3: GTMContentGroup.tertiary.WELCOME
    }
  }
}

const onScannedCode = async (code?: string) => {
  if (!code || scanned.value) return

  // check valid route to continue scanning
  let canContinue = scannerActiveRouteNames.includes(route.name as string)
  if (route.name === 'checkout') {
    // check checkout only first step is available
    canContinue = route.query.step === 'payment-method'
  }
  if (!canContinue) return

  logInfo(log, 'Scanned code => ***' + code + '***')
  scanned.value = code

  // check code type
  if (scanned.value.includes('FF99') || scanned.value.includes('|')) {
    const [mcId] = scanned.value.split(/\||FF99/g)
    if (!mcId) {
      reset()
      return
    }
    if (store.user?.mcId === mcId) {
      // dont try to login currently logged user ...
      reset()
      return
    }
    processing.value = 'identification'
    await scanProcessIdentification(mcId)
  } else {
    const regex = new RegExp(/^[A-Z0-9]{9}$/gm)
    if (!regex.test(scanned.value)) {
      logInfo(log, 'Seems scanned code is not a valid coupon code')
      reset()
      return
    }
    if (!store.cart) {
      // no session available yet
      logInfo(log, 'Session is not started yet, user cannot try to apply coupon')
      return
    }
    await scanProcessCoupon({
      coupon: scanned.value,
      mcId: scanned.value.substring(3),
      sessionId: store.cart.sessionId
    })
  }
}

async function eventListenerScan(ev: CustomEvent) {
  const { scanCode } = ev.detail
  await onScannedCode(scanCode)
}

const removeListener = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document.removeEventListener<any>('scan', eventListenerScan, true)
}

const scanProcessIdentification = async (mcId: string) => {
  logInfo(log, 'Starting to identificate with mcId = ' + mcId)

  const user = await store.logInUserAction({ mcId })

  if (user) {
    reveal(identificationSuccesDialogProps(user.name)).then(() => {
      processing.value = undefined
    })
    onConfirm(async () => {
      trackLogin(LoginMethod.QR_IDENTIFICATION)
      // if on identification go to path from where you enter identification
      if (route.name === 'identification') {
        const path = route.query.from ? (route.query.from as string) : '/home'
        await router.push({
          path
        })
      } else if (route.name === 'attract') {
        await router.push({
          name: 'prehome'
        })
      }
      reset()
    })
  } else {
    reveal(identificationErrorDialogProps).then(() => {
      processing.value = undefined
    })
    onCancel(async () => {
      // only terminate process
      reset()
    })
    onConfirm(async () => {
      // go to identification (if not is there already)
      if (route.name !== 'identification') {
        await router.push({
          name: 'identification',
          query: { from: route.path, name: route.name as string }
        })
      }
      reset()
    })
  }
}

const onCancelCouponError = async () => {
  if (!store.cart) {
    await router.push({ name: 'prehome' })
  } else await router.push({ name: 'home' })
}

const couponError = async (type: 'aopCouponAlreadyUsed' | 'aopCouponError' = 'aopCouponError') => {
  const dialogProps = {
    ...couponErrorDialogProps,
    title: t(`modals.${type}.title`),
    description: t(`modals.${type}.description`)
  }
  reveal(dialogProps).then(() => {
    reset()
  })
  onCancel(async () => {
    await onCancelCouponError()
  })
  onConfirm(async () => {
    await router.push({ name: 'aop-coupon' })
  })
}

const scanProcessCoupon = async ({
  coupon,
  mcId,
  sessionId
}: {
  coupon: string
  mcId: string
  sessionId: string
}) => {
  logInfo(log, 'Starting coupon process')
  const isUserLogged = !!store.user
  // start logging process too
  if (!isUserLogged) {
    processing.value = 'identification'
    logInfo(log, 'Starting to identificate with mcId = ' + mcId)
    const user = await store.logInUserAction({ mcId })
    if (!user) {
      logInfo(log, 'User could not be logged from coupon')
      await couponError()
      return
    }
  }

  try {
    processing.value = 'coupon'
    const { products } = await aopService.aopCheckCoupon(coupon, sessionId)
    if (!products.length) {
      logInfo(log, 'Coupon has no products')
      await couponError()
      return
    }
    // single product
    trackRedeem({ coupon, method: LoginMethod.QR_IDENTIFICATION })
    if (products.length === 1) {
      const { idCategory, identifier } = products[0]
      // navigate to product detail
      logInfo(log, 'Navigating to product detail with aopCoupon = ' + coupon)
      await router.push({
        name: 'product',
        params: { categoryId: idCategory, productId: identifier },
        query: { aopCoupon: coupon }
      })
    } else {
      // several products
      store.setAopCouponProducts(products)
      logInfo(log, 'Navigating to aop-coupon-products with aopCoupon = ' + coupon)
      // navigate
      await router.push({
        name: 'aop-coupon-products',
        query: { aopCoupon: coupon, fromPath: route.path }
      })
    }
    reset()
  } catch (error) {
    logErrorApi('scanProcessCoupon', error, log)
    const errorKey = getErrorKey(error)
    await couponError(
      errorKey === BackendCustomException[610] ? 'aopCouponAlreadyUsed' : 'aopCouponError'
    )
  }
}

const reset = () => {
  scanned.value = undefined
  processing.value = undefined
}

onMounted(() => {
  removeListener()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document.addEventListener<any>('scan', eventListenerScan, true)
})

onBeforeUnmount(() => {
  removeListener()
})
</script>

<template>
  <span data-test="scanner-keep-alive" class="absolute top-0 left-0">
    <!-- Processing -->
    <transition name="fade-b" mode="out-in">
      <FullScreenLoader
        v-if="processing"
        :title="t(`${processing}.processing`)"
        lottie-key="loader-fries"
      />
    </transition>
  </span>
</template>

<script lang="ts" setup>
import ConfirmDialog from '@components/ui/modal/ConfirmDialog.vue'
import AdkButton from '@components/ui/AdkButton.vue'
import { useLocale } from '@composables/useLocale'
import { ConfirmDialogProps } from '@lib/models/modals'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { useAppStore } from '@stores/app.store'
import { useRoute, useRouter } from 'vue-router'
import { useGtm } from '@composables/useGtm'

const { t } = useLocale()
const { clearUserData } = useAppStore()
const router = useRouter()
const route = useRoute()
const { trackOnCloseModal, trackLogout } = useGtm()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialog = createConfirmDialog(ConfirmDialog as any)

const showSignoutModal = async () => {
  const dialogProps: ConfirmDialogProps = {
    title: t('modals.logoutModal.title'),
    description: t('modals.logoutModal.description'),
    primaryButton: t('actions.continue'),
    secondaryButton: t('actions.cancel'),
    imageUrl: 'images/icons/avatar.svg'
  }
  const { isCanceled } = await dialog.reveal(dialogProps)

  if (isCanceled) return trackOnCloseModal()

  await clearUserData({ cleanSession: true })
  trackLogout()
  await router.push({ name: route.name === 'prehome' ? route.name : 'home' })
}
</script>

<template>
  <AdkButton
    data-test="btn-signout"
    class="w-[17.13vw]"
    size="small"
    is-primary
    @press="showSignoutModal"
    >{{ t('actions.signOut') }}</AdkButton
  >
</template>

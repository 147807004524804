<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { scopedLogger, logInfo } from '@lib/utils/logUtils'
import { useLocale } from '@composables/useLocale'
import AdkButton from '@components/ui/AdkButton.vue'
import { useGtm } from '@composables/useGtm'

const log = scopedLogger('@components/Attract.vue')

const { t } = useLocale()
const router = useRouter()
const { trackGoEcommerce } = useGtm()

async function doContinue() {
  logInfo(log, 'Continue to Prehome')
  trackGoEcommerce()
  // navigate to Prehome
  await router.push({ name: 'prehome' })
}
</script>

<template>
  <!-- TO DO adjust texts in case of changing logged/not logged behaviour -->
  <AdkButton
    data-test="btn-footer-continue-without-login"
    class="w-full flex flex-col justify-center"
    size="extralarge"
    @press="doContinue"
  >
    <p class="font-bold text-h3">
      {{ t('actions.access.title') }}
    </p>
    <p class="text-h5">
      {{ t('actions.access.subtitle') }}
    </p>
  </AdkButton>
</template>

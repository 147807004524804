<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { SlotDialogProps } from '@lib/models/modals'
import BaseDialog from './BaseDialog.vue'

defineProps<SlotDialogProps>()

const emit = defineEmits(['confirm', 'cancel'])

const shown = ref(false)

const waitXMillis = async (millis: number) =>
  await new Promise((resolve) => setTimeout(resolve, millis))

const doAction = async (action: 'confirm' | 'cancel') => {
  shown.value = false
  await waitXMillis(50)
  emit(action)
}

onMounted(async () => {
  await waitXMillis(50)
  shown.value = true
})
</script>

<template>
  <BaseDialog>
    <div
      v-if="shown"
      data-test="slot-dialog"
      class="w-full h-full fixed top-0 left-0 z-10 bg-white flex flex-col justify-center"
    >
      <component :is="slotComponent" :data="data" @action="doAction" />
    </div>
  </BaseDialog>
</template>

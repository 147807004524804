import { z } from 'zod'
import { optionsGroupsMainSchema } from './optionGroups'
import { localImageSchema, priceSchema } from './common'

export const SizeId = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
  NONE: 'NONE'
} as const

const sizeUnion = z.union([
  z.literal(SizeId.LARGE),
  z.literal(SizeId.MEDIUM),
  z.literal(SizeId.SMALL),
  z.literal(SizeId.NONE)
])

export type SizeType = z.infer<typeof sizeUnion>

export const productLiteSchema = z.object({
  identifier: z.string(),
  name: z.string(),
  imageUrl: localImageSchema,
  description: z.string().optional().default(''),
  price: priceSchema,
  unifiedPrice: priceSchema.optional(),
  originalPrice: priceSchema.optional(),
  size: sizeUnion.optional(),
  barcode: z.string().optional()
})

export type ProductLite = z.infer<typeof productLiteSchema>

export const productLiteCategorized = productLiteSchema.extend({
  idCategory: z.string().toLowerCase()
})

export type ProductLiteCategorized = z.infer<typeof productLiteCategorized>

const sizeSchema = z.object({
  id: sizeUnion,
  code: z.string(),
  idCategory: z.string().toLowerCase(),
  price: priceSchema,
  imageUrl: localImageSchema,
  name: z.string()
})

export type ProductSize = z.infer<typeof sizeSchema>

export const productDto = productLiteCategorized.extend({
  idCategory: z.string(),
  combo: z.boolean(),
  optionsGroups: z.array(optionsGroupsMainSchema),
  sizes: z.array(sizeSchema).optional()
})

export type Product = z.infer<typeof productDto>

/* CrossSelling */
export const crossSellingProducts = z.array(productLiteCategorized)

export type ProductExtraInfo = {
  identifier: string
  unitPriceAmount: number
  unifiedPriceAmount?: number
  computedUnitPrice: number
  customization: string
}

<script lang="ts" setup>
import ConfirmDialog from '@components/ui/modal/ConfirmDialog.vue'
import AdkButton from '@components/ui/AdkButton.vue'
import { useCartActions } from '@composables/useCartActions'
import { useLocale } from '@composables/useLocale'
import { ConfirmDialogProps } from '@lib/models/modals'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { useGtm } from '@composables/useGtm'
import { GTMContentGroup } from '@lib/models/gtm'

const { t } = useLocale()
const { sendReset } = useCartActions()
const { trackOnCloseModal, trackOrderCancelledBefore } = useGtm()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialog = createConfirmDialog(ConfirmDialog as any)

const showCancelModal = async () => {
  const dialogProps: ConfirmDialogProps = {
    title: t('modals.confirmCancelOrder.title'),
    description: t('modals.confirmCancelOrder.description'),
    primaryButton: t('actions.cancelOrder'),
    secondaryButton: t('actions.back'),
    contentGroup: {
      cg: GTMContentGroup.primary.ECOMMERCE,
      cg2: GTMContentGroup.secondary.ORDER_CANCELLED
    }
  }
  const modal = await dialog.reveal(dialogProps)

  if (modal.isCanceled) return trackOnCloseModal()

  trackOrderCancelledBefore()
  await sendReset()
}
</script>

<template>
  <AdkButton
    class="w-[calc(50%-1.48vw/2)]"
    size="small"
    data-test="btn-cancel-order"
    @press="showCancelModal"
  >
    {{ t('actions.cancelOrder') }}
  </AdkButton>
</template>
